import React, { Component, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mudir from "../../assets/images/kafedra/asqarov.jpg";
import { IoLocationSharp, BsBarChartSteps } from "react-icons/io5";
import ReactHTMLParser from "react-html-parser";
import {
  FaSortAmountUpAlt,
  FaUserAlt,
  FaPhoneAlt,
  FaChartBar,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function FakultetDetails(props) {
  const [toggleState, setToggleState] = useState(1);
  const MyList = props.fakultetData;

  const toggleTab = (index) => {
    setToggleState(index);
  };

// useEffect(()=>{
//   // window.scroll(0,0);
// },[])

  const MyView = MyList.map((FakultetList, i) => {
    return (
      <div key={i.toString()}>
        <Row>
          <h1 className="text-center">{FakultetList.name_uz}</h1>
          <hr />
          <Col xl={3} lg={3} md={6} sm={12} xs={12}>
            <img className="ChairImg" src={FakultetList.dekan_photo} />
          </Col>
          <Col xl={9} lg={9} md={9} sm={12} xs={12}>
            <h4>
              <FaUserAlt />
              <span className="ms-3">{FakultetList.dekan_name}</span>
            </h4>
            <h4>
              <FaPhoneAlt />
              <span className="ms-3">{FakultetList.dekan_telephone}</span>
            </h4>
            <h4>
              {" "}
              <MdEmail />
              <span className="ms-3">{FakultetList.dekan_email}</span>
            </h4>
            <h4>
              {" "}
              <FaChartBar />
              <span className="ms-3">{FakultetList.dekan_lavozim_uz}</span>
            </h4>
            <h4>
              <IoLocationSharp />
              <span className="ms-3">{FakultetList.dekan_location}</span>
            </h4>
            <h4>
              <FaSortAmountUpAlt />
              <span className="ms-3">{FakultetList.dekan_daraja_uz}</span>
            </h4>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="containertabs">
              <div className="bloc-tabs">
                <button
                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  Fakultet haqida
                </button>
                <button
                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  Biografiya
                </button>
                <button
                  className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(3)}
                >
                  Nashrlar
                </button>
                <button
                  className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(4)}
                >
                  Loyihalar
                </button>
                <button
                  className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(5)}
                >
                  Xodimlar
                </button>
              </div>

              <div className="content-tabs">
                <div
                  className={
                    toggleState === 1 ? "content  active-content" : "content"
                  }
                >
                  {ReactHTMLParser(FakultetList.information_uz)}
                </div>

                <div
                  className={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  {ReactHTMLParser(FakultetList.dekan_bio_uz)}
                </div>

                <div
                  className={
                    toggleState === 3 ? "content  active-content" : "content"
                  }
                >
                  {ReactHTMLParser(FakultetList.dekan_nashr_uz)}
                </div>

                <div
                  className={
                    toggleState === 4 ? "content  active-content" : "content"
                  }
                >
                  {ReactHTMLParser(FakultetList.dekan_loyihalar_uz)}
                </div>

                <div
                  className={
                    toggleState === 5 ? "content  active-content" : "content"
                  }
                >
                  <h2>O'qituvchilar</h2>
                  <hr />
                  <p>O'qituvchilar</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <Fragment>
      <div className="ChairRow">{MyView}</div>
    </Fragment>
  );
}
