import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function FooterDesktop() {

  const { t } = useTranslation();

  const contact=t('contact');
  const social_contact=t('social_contact');
  const about_us=t('about_us');
  const about_us_text=t('about_us_text');



  return (
    <div className="footer">
      <Container>
        <Row>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <h4 class="text-white my-3">{contact}</h4>
            <div class="contact d-flex text-white">
              <div class="phone">
                <i class="fas fa-phone"></i>
              </div>
              <h5>+998(79)-223-53-62 - ishonch raqami</h5>
            </div>
            <div class="contact d-flex text-white mt-2">
              <div class="phone">
                <i class="fas fa-phone"></i>
              </div>
              <h5>+998(79)-223-49-66</h5>
            </div>
            
            <div class="contact d-flex text-white my-2">
              <div class="phone">
                <i class="fas fa-map-marker-alt"></i>
              </div>
              <h5>Navoiy shahar, G'alabashox ko'cha</h5>
            </div>
            <div class="contact d-flex text-white">
              <div class="phone">
                <i class="fas fa-envelope"></i>
              </div>
              <h5>info@nsumt.uz</h5>
            </div>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <h4 class="text-white my-3">{social_contact}</h4>
            <a
              href="https://t.me/ndkiofficial"
              class="text-decoration-none"
              target="_blank"
            >
              <div class="contact d-flex text-white ">
                <div class="social">
                  <i class="fab fa-telegram-plane"></i>
                </div>
                <h5>NDKTU-telegram</h5>
              </div>
            </a>
            <a
              href="https://www.facebook.com/navoiydavlat.konchilikinstituti.1"
              class="text-decoration-none"
              target="_blank"
            >
              <div class="contact d-flex text-white my-2">
                <div class="social">
                  <i class="fab fa-facebook-f"></i>
                </div>
                <h5>NDKTU-facebook</h5>
              </div>
            </a>
            <a
              href="https://www.instagram.com/navoiydavlatkonchilikinstituti/"
              class="text-decoration-none"
              target="_blank"
            >
              <div class="contact d-flex text-white my-2">
                <div class="social">
                  <i class="fab fa-instagram"></i>
                </div>
                <h5>NDKTU-instagram</h5>
              </div>
            </a>
            <a
              href="https://www.youtube.com/channel/UCKQtUSMB1dw0ikzbeCEYEWA"
              class="text-decoration-none"
              target="_blank"
            >
              <div class="contact d-flex text-white mt-2">
                <div class="social">
                  <i class="fab fa-youtube"></i>
                </div>
                <h5>NDKTU-youtube</h5>
              </div>
            </a>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <h4 class="text-white my-3">{about_us}</h4>
            <p class="text-white about">
             {about_us_text}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
