import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { Card, Col, Container, Row } from "react-bootstrap";
import news from "../../assets/images/1.jpg";
import news1 from "../../assets/images/1.jpg";
import news2 from "../../assets/images/2.jpg";
import news3 from "../../assets/images/3.jpg";
import news4 from "../../assets/images/4.jpg";
import ReactHTMLParser from "react-html-parser";
import MegaMenu from "../../components/home/MegaMenu";
import { useTranslation, initReactI18next } from "react-i18next";

export default function VerSubCategory(props) {
  const MyList = props.versubcategoryData;

  const VerCategory = props.vercategory;
  const VerSubCategory = props.versubcategory;
  const { t } = useTranslation();

  const vminfo=t('vminfo');
  const vminfo_title=t('vminfo_title');

  const MyView = MyList.map((VerSubCategoryList, i) => {
    return (
      <div>
        <div className="InfoBradCamp">
          <span className="CategoryBrad">{VerCategory}</span>
          <i className="fa fa-chevron-right"></i>
          <i className="fa fa-chevron-right"></i>
          <span className="SubCategoryBrad">{VerSubCategory}</span>
        </div>
        <div className="InfoMain">
          <h2>{VerSubCategoryList[vminfo]} </h2>
          <hr/>
          <div className="OtherTextNews">
            {ReactHTMLParser(VerSubCategoryList[vminfo_title])}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Fragment>
      <Container>
        <Row className="my-2">
        <Col xl={4} lg={4} md={4} sm={12} xs={12} className="">
        <div className="MegaMenuVer">
              <MegaMenu />
            </div>
           
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            {MyView}
          </Col>

          
        </Row>
      </Container>
    </Fragment>
  );
}
