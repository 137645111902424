import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { Card, Col, Container, Row } from "react-bootstrap";
import news from "../../assets/images/1.jpg";
import news1 from "../../assets/images/1.jpg";
import news2 from "../../assets/images/2.jpg";
import news3 from "../../assets/images/3.jpg";
import news4 from "../../assets/images/4.jpg";
import ReactHTMLParser from "react-html-parser";
import { useTranslation, initReactI18next } from "react-i18next";

export default function NewsDetails(props) {
  const MyList = props.newsbockData;
  const { t } = useTranslation();

  const news=t('news');
  const news_text=t('news_text');
  const MyView = MyList.map((NewsList, i) => {
    return (
      <Fragment >
        <div key={i.toString()} className="NewsDetails">
          <h2>{NewsList[news]} </h2>
          <img className="CardImgDetail" variant="top" src={NewsList.img} />
          <hr/>
          <p className="OtherTextNews">
          {ReactHTMLParser(NewsList[news_text])}
          </p>
        </div>
      </Fragment>
    );
  });
  return <Fragment>{MyView}</Fragment>;
}
