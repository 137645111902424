import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavMenuDesktop from "../components/common/NavMenuDesktop";

import New from "../components/OtherPage/InfoMain";
import FooterDesktop from "../components/common/FooterDesktop";


import Rahbariyat from "../components/OtherPage/Rahbariyat";

import MegaMenu from "../components/home/MegaMenu";
import axios from "axios";
import AppURL from "../api/AppURL";

export default function RahbariyatPage() {
  const [rahbariyatData, setrahbariyatData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(AppURL.AllRahbariyat)
      .then((res) => {
        let StatusCode = res.status;
        if (StatusCode === 200) {
            setrahbariyatData(res.data);
        }
      })
      .catch((error) => {});
  });

  return (
    <Fragment>
      <div className="Desktop">
        <div className="NavMenuVer">
          <NavMenuDesktop />
        </div>
      </div>
      <Container>
        <Row className="my-2">
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="MegaMenuVer">
              <MegaMenu />
            </div>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <Rahbariyat rahbariyatData={rahbariyatData} />
          </Col>
        </Row>
      </Container>
      <FooterDesktop />
    </Fragment>
  );
}
