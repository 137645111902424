import React, { Component, Fragment } from "react";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import NavLogoMobile from "../navMobile/NavLogoMobile";
import NavMenuMobile from "../navMobile/NavMenuMobile";

class navMenuMobile extends Component {
  render() {
    return (
      <div>
        <Fragment>
          <Navbar bg="light" className="NavbarTop">
            <Container fluid="true">
              <NavLogoMobile />
              <NavMenuMobile />
            </Container>
          </Navbar>
          {/* <div className="sideNavOpen">
          
            <hr className="w-80" />
            <div className="list-group">
              <a className="list-group-item nav-font nav-itemmenu">
                
                <i className="fa fa-home"></i>
              </a>
            </div>
          </div>
          <div className="ContentOverlayOpen"></div> */}
        </Fragment>
      </div>
    );
  }
}

export default navMenuMobile;
