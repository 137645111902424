import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import rec1 from "../../assets/images/recommendations/1.svg";
import rec2 from "../../assets/images/recommendations/2.svg";
import rec3 from "../../assets/images/recommendations/3.svg";
import rec4 from "../../assets/images/recommendations/4.svg";
import rec5 from "../../assets/images/recommendations/5.svg";
import rec6 from "../../assets/images/recommendations/6.svg";
import rec7 from "../../assets/images/recommendations/7.svg";
import rec8 from "../../assets/images/recommendations/8.svg";
import rec9 from "../../assets/images/recommendations/9.svg";

export default function Recomendation(props) {
  const MyList = props.kafedraDataR;


  const MyView = MyList.map((KafedraList, i) => {
    return (
      <li key={i.toString()}>
        <Link to={"/chair/" + KafedraList.id} className="KafLink">
          <i class="fas fa-chevron-right me-2"></i>
          <span>{KafedraList.name_uz}</span>
        </Link>
      </li>
    );
  });

  return (
    <Fragment>
      <Container className="text-center RecContainer">
        <Row>
          <Col xl={7} lg={12} md={12} sm={12} xs={12}>
            <Row>
              <div className="mb-3">
                <h1>Tavsiya etiladigan linklar</h1>
              </div>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="#">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec1} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">Qabul 2023/2024</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="https://t.me/NDKTU_REKTOR_BOT" target="blank">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec2} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">
                          Rektorga online murojaat
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="#">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec3} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">Ilm-fan 2023</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="#">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec4} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">
                          Intraktiv xizmatlar
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="http://kitob.ngmk.uz/" target="_blank">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec5} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">onlayn kutubxona</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="https://lex.uz/uz/" target="_blank">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec6} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">Ko'rsatmalar</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="https://student.ndki.uz/dashboard/login" target="_blank">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec7} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">
                          Talaba hemis
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="http://vq.ndki.uz/" target="_blank">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec8} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">Virtulal qabul</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} className="mb-2">
                <a href="https://www.scopus.com/" target="_blank">
                  <Card className="RecCard">
                    <Card.Img className="RecImg text-center" src={rec9} />
                    <Card.Body>
                      <Card.Text>
                        <p className="CardText text-center">Skopus</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xl={5} lg={12} md={12} sm={12} xs={12}>
            <div className="Kafedra">
              <h1 className="mb-3">Kafedralar</h1>
              <uL>
                {MyView} 
              </uL>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
