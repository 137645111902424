import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mudir from "../../assets/images/kafedra/asqarov.jpg";
import { IoLocationSharp, BsBarChartSteps } from "react-icons/io5";
import ReactHTMLParser from "react-html-parser";
import { useTranslation, initReactI18next } from "react-i18next";
import {
  FaSortAmountUpAlt,
  FaUserAlt,
  FaPhoneAlt,
  FaChartBar,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function RahbariyatDetal(props) {
  const [toggleState, setToggleState] = useState(1);
  const MyList = props.rahbariyatdetalData;
  const { t } = useTranslation();

  const management_info=t('management_info');
  const management_name=t('management_name');

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const MyView = MyList.map((RahbariyatDetalList, i) => {
    return (
      <div key={i.toString()}>
        <Row>
          <h1 className="text-center">{RahbariyatDetalList[management_name]}</h1>
          <hr />
          <Col xl={3} lg={3} md={6} sm={12} xs={12}>
            <img className="ChairImg" src={RahbariyatDetalList.photo} />
          </Col>
          <Col xl={9} lg={9} md={9} sm={12} xs={12}>
            <h4>
              <FaUserAlt />
              <span className="ms-3">{RahbariyatDetalList.fish}</span>
            </h4>
            <h4>
              <FaPhoneAlt />
              <span className="ms-3">{RahbariyatDetalList.telephone}</span>
            </h4>
            <h4>
              {" "}
              <MdEmail />
              <span className="ms-3">{RahbariyatDetalList.email}</span>
            </h4>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <p>{ReactHTMLParser(RahbariyatDetalList[management_info])}</p>
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <Fragment>
      <div className="ChairRow">{MyView}</div>
    </Fragment>
  );
}
