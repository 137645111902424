import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavMenuDesktop from "../components/common/NavMenuDesktop";

import New from "../components/OtherPage/InfoMain";
import FooterDesktop from "../components/common/FooterDesktop";

import Kafedra from "../components/OtherPage/Kafedra";

import MegaMenu from "../components/home/MegaMenu";
import axios from "axios";
import AppURL from "../api/AppURL";

export default function KafedraPage() {
  const [kafedraData, setkafedraData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(AppURL.AllKafedralar)
      .then((res) => {
        let StatusCode = res.status;
        if (StatusCode === 200) {
          setkafedraData(res.data);
        }
      })
      .catch((error) => {});
  },[kafedraData]);

  return (
    <Fragment>
      <div className="Desktop">
        <div className="NavMenuVer">
          <NavMenuDesktop />
        </div>
      </div>
      <Container>
        <Row className="my-2">
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className="MegaMenuVer">
              <MegaMenu />
            </div>
          </Col>
          <Col xl={8} lg={8} md={8} sm={12} xs={12}>
            <Kafedra kafedraData={kafedraData} />
          </Col>
        </Row>
      </Container>
      <FooterDesktop />
    </Fragment>
  );
}
