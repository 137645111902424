import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavMenuDesktop from "../components/common/NavMenuDesktop";

import New from "../components/OtherPage/InfoMain";
import FooterDesktop from "../components/common/FooterDesktop";


import NewsBlock from "../components/OtherPage/NewsBlock";

import MegaMenu from "../components/home/MegaMenu";
import axios from "axios";
import AppURL from "../api/AppURL";

export default function NewsBlockPage() {
  const [newsblockdata, setnewsblockdata] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(AppURL.AllNewsBlock)
      .then((res) => {
        let StatusCode = res.status;
        if (StatusCode === 200) {
            setnewsblockdata(res.data);
        }
      })
      .catch((error) => {});
  });

  return (
    <Fragment>
      <div className="Desktop">
        <div className="NavMenuVer">
          <NavMenuDesktop />
        </div>
      </div>
      <Container>
        <Row className="my-2">
            
          <Col xl={12} lg={12} md={12} sm={12} xs={12} >
            <NewsBlock newsblockdata={newsblockdata} />
          </Col>
        </Row>
      </Container>
      <FooterDesktop />
    </Fragment>
  );
}
