import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mudir from "../../assets/images/kafedra/asqarov.jpg";
import { BsFillPersonFill, IoSchoolSharp } from "react-icons/bs";
import ReactHTMLParser from "react-html-parser";
import { useTranslation, initReactI18next } from "react-i18next";

import { MdEmail } from "react-icons/md";

export default function Management(props) {
  const MyList = props.rahbariyatData;
  const { t } = useTranslation();
  const management_name_sub=t('management_name_sub');


  const MyView = MyList.map((RahbariyatList, i) => {
    return (
      <Col key={i.toString()} xl={6} lg={6} md={12} sm={12} xs={12}>
        <Link to={"/rahbariyat/"+RahbariyatList.id} className="KafedraLink">
          <div className="KafedraMudir">
            {/* <IoSchoolSharp  className="Ioschool"/> */}
            <span>
              <BsFillPersonFill className="Iobook" />
              {RahbariyatList[management_name_sub]}
            </span>
          </div>
        </Link>
      </Col>
    );
  });

  return (
    <Fragment>
     
      <Row className="KafedraRow">
      <h1 className="text-center mb-3">Rahbariyat</h1>
        {MyView}
       
      </Row>
     
    </Fragment>
  );
}
