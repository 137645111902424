import React,  {Component, Fragment} from "react";

class AboutPage extends Component{
    render() {
        return(
            <Fragment>
                <h1>About</h1>
            </Fragment>
        )
    }
}
export default AboutPage