import React, { Fragment, useEffect, useRef, useState } from "react";

import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import book from "../../assets/images/icons/saving_book.png";
import hemis from "../../assets/images/icons/hemis.png";
import symbol from "../../assets/images/symbols/symbol.svg";
import uzbek from "../../assets/images/languages/uzbek.svg";
import russian from "../../assets/images/languages/russian.svg";
import english from "../../assets/images/languages/english.svg";
import HomePage from "../../pages/HomePage";
import i18n from "i18next";

import Backend from "i18next-xhr-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";
import NavMenu from "./NavMenu";
// import { useTranslation } from "react-i18next";
import "bootstrap/dist/js/bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import i18next from "i18next";

import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
// import 'flag-icon-css/css/flag-icon.min.css';

export default function NavLogo() {
  const [openSimvol, setOpenSimvol] = useState(false);
  const [openSDG, setOpenSDG] = useState(false);
  const [openLanguage, setOpenLanguage] = useState("");

  const simvolRef = useRef();
  const sdgRef = useRef();
  const { t } = useTranslation();
  function handleClick(lang) {
    i18n.changeLanguage(lang);
   };
  useEffect(() => {
    const closeDropSimvol = (event) => {
      if (!simvolRef.current) {
        setOpenSimvol(false);
      }
    };
    const closeDropSDG = (event) => {
      if (!sdgRef.current) {
        setOpenSDG(false);
      }
    };

    

    // console.log(openLanguage);
    document.addEventListener("mousedown", closeDropSimvol);
    document.addEventListener("mousedown", closeDropSDG);
    // document.addEventListener("onclick", closeDropLanguage);
  }, []);
  const language = [
    {
      code: "uz",
      name: "uzbek",
      country_code: "uz",
    },
    {
      code: "ru",
      name: "rus",
      country_code: "ru",
    },
    {
      code: "en",
      name: "eng",
      country_code: "en",
    },
  ];
  return (
    <Fragment>
      <Row className="NavbarTopRow">
        <Col xl={4} lg={4} md={4} sm={12} xs={12} className="EduCol">
          <a
            href="http://edu.nsumt.uz/"
            className="EduLinkm me-2"
            target="_blank"
          >
            <img className="hemis" src={book} />
            <span>Education</span>
          </a>
          <a
            href="https://hemis.ndki.uz/dashboard/login"
            className="EduLink"
            target="_blank"
          >
            <img className="hemis" src={hemis} />
            <span>Hemis</span>
          </a>
          <a
            href="https://student.ndki.uz/dashboard/login"
            className="EduLink ms-2"
            target="_blank"
          >
            <img className="hemis" src={hemis} />
            <span> Student</span>
          </a>
        </Col>
        {/* <Col xl={2} lg={2} md={2} sm={12} xs={12} className="EduCol">
        
      </Col> */}
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <div className="NavSearch">
            <input />
            <button>
              <i className="fa fa-search"></i>
            </button>
          </div>
        </Col>
        <Col xl={2} lg={2} md={2} sm={12} xs={12}>
          <div className="simvol">
                <ul>
                  <li className="me-2">
                    <a
                      href="http://sdg.nsumt.uz/"
                      className="simvolLink"
                      target="black"
                      
                    >
                      SDG_Hub
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://greenmetric.nsumt.uz/"
                      className="simvolLink"
                      target="black"
                    >
                      GreenMetric
                    </a>
                  </li>
                </ul>
             
           
          </div>
          {/* <h2>{t("welcom")}</h2> */}
        </Col>
        {/* <Col xl={2} lg={2} md={2} sm={12} xs={12}>
          <div className="simvol">
            <button ref={sdgRef} onClick={() => setOpenSimvol(!openSDG)}>
              <img src={symbol} />
              Davlat ramzlari
              <i
                class={
                  openSDG
                    ? "fas fa-chevron-down openSimvol"
                    : "fas fa-chevron-down"
                }
              ></i>
              <div
                className={
                  openSDG ? "simvolContent openSimvol" : "simvolContent"
                }
              >
                <ul>
                  <li>
                    <Link to="#" className="simvolLink">
                      Bayroq
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="simvolLink">
                      Gerb
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="simvolLink">
                      Madhiya
                    </Link>
                  </li>
                </ul>
              </div>
            </button>
          </div>
        </Col> */}
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
         

          <div className="simvollanguage">
            <div>
              <Link
                to="#"
                className="simvolLink ps-5"
                onClick={() => handleClick("uz")}
              >
                <img src={uzbek} />
              </Link>
            </div>
            <div>
              <Link
                to="#"
                className="simvolLink"
                onClick={() => handleClick("ru")}
              >
                <img src={russian} />
              </Link>
            </div>
            <div>
              <Link
                to="#"
                className="simvolLink"
                onClick={() => handleClick("en")}
              >
                <img src={english} />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <div className="">
        <NavMenu openLanguage={openLanguage} />
      </div>
    </Fragment>
  );
}
