import React, { Component, Fragment } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import MegaMenu from "./MegaMenu";
import SliderMenu from "./SliderMenu";

export default function HomeTop () {
  
    return (
      <Fragment >  
        <Container className="homeTop">
          <Row>
            <Col lg={4} md={4} sm={12}>
              <MegaMenu />
            </Col>
            <Col lg={8} md={8} sm={12}>
              <SliderMenu />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  
}


