import React, { Component, Fragment } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import news1 from "../../assets/images/1.jpg";
import news2 from "../../assets/images/2.jpg";
import news3 from "../../assets/images/3.jpg";
import news4 from "../../assets/images/4.jpg";
import { Link } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GrLinkNext } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import ReactHTMLParser from "react-html-parser";

export default function News(props) {
  const MyList = props.yangiliklarData;
  const { t } = useTranslation();

  const newsh=t('news');
  const news_text=t('news_text');
  const newn=t('nobase');
  const more=t('more');
  const new_all=t('nobase_all');

  const MyView = MyList.slice(0, 4).map((YangilikList, i) => {
    return (
      <Col
        className="mb-4"
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        key={i.toString()}
      >
        <Card className="Card HomeNewsBlock">
          <Card.Img className="CardImg" variant="top" src={YangilikList.img} />
          <Card.Body>
            <Card.Text>
              <p className="CardText">  
                {/* {ReactHTMLParser(YangilikList[news].substring(0, 90))} */}
                {ReactHTMLParser(YangilikList[newsh].substring(0, 90))}..
                
              </p>
            </Card.Text>
            <div className="DataLink">
              <p className="CardData">
              <hr />
                {" "}
                <FaRegCalendarAlt className="me-2" /> {YangilikList.update_at}
              </p>
              <Link to={"/news/" + YangilikList.id} className="LinkNews">
                {more}
              </Link>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  });
  const MyElon = MyList.slice(0, 4).map((YangilikList, i) => {
    return (
      <Col
        className="mb-2"
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        key={i.toString()}
      >
        <Card.Body>
          <Link className="AnonsLink" to={"/news/" + YangilikList.id}>
            <Card.Text className="AnonsTextBlock">
              <p className="AnonsText">
                {/* {YangilikList.title_uz.substring(0, 120)}... */}
                {ReactHTMLParser(YangilikList[newsh].substring(0, 90))}..
              </p>
              <span>
                <FaRegCalendarAlt className="me-2" /> {YangilikList.update_at}
              </span>
              <hr />
            </Card.Text>
          </Link>
        </Card.Body>
      </Col>
    );
  });

  return (
    <Fragment>
      <Container>
        <Row>
          <Col xl={7} lg={7} md={12} sm={12} xs={12}>
            <div className="Block mb-4">
              <h2>{newn}</h2>
              <a href="#">{new_all}</a>
            </div>
            <Row className="mb-5">
             
              {MyView}
              
              </Row>
          </Col>
          <Col xl={5} lg={5} md={12} sm={12} xs={12} className="mt-1">
            <Card className="Anons">
              <div className="AnonsBlock ms-3">
                <h2>{newn}</h2>
                <a href="#">{new_all}</a>
              </div>

              <Row>{MyElon}</Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
