import React, { Component, Fragment } from "react";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import NavLogo from "../navDesktop/NavLogo";
import NavMenu from "../navDesktop/NavMenu";

export default function navMenuDesktop() {
  return (
    <Fragment>
      <Navbar bg="light" className="NavbarTop">
        <Container fluid="true">
          <div className="ContainerDesktop">
            <NavLogo />
            {/* <NavMenu /> */}
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}
