import React, { Component, Fragment, useEffect } from "react";
import { BrowserRouter} from "react-router-dom";
import AppRoute from "./route/AppRoute";

export default function App() {
  

    
    return (
      <div>
        <Fragment>
          <BrowserRouter>
            <AppRoute />
          </BrowserRouter>
        </Fragment>
      </div>
    );
  }



