import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavMenuDesktop from "../components/common/NavMenuDesktop";

import New from "../components/OtherPage/InfoMain";
import FooterDesktop from "../components/common/FooterDesktop";
import BolimDetalis from "../components/OtherPage/BolimDetalis";
import Recomendation from "../components/home/Recomendation";
import MegaMenu from "../components/home/MegaMenu";
import axios from "axios";
import AppURL from "../api/AppURL";

export default function ChairPage() {


  const [bolimData, setBolimData] = useState([]);
  // const {name_uz} = useParams();
  const {id} = useParams();

  useEffect(() => {
    // window.scroll(0, 0);
    axios
    .get(AppURL.BolimlarById(id))
    .then((res) => {
      let StatusCode = res.status;
      if(StatusCode===200){
        setBolimData(res.data);
      }
    })
    .catch((error) => {});
  },[bolimData, id]);

  return (
    <Fragment>
      <div className="Desktop">
        <NavMenuDesktop />
      </div>
      <Container>
      <Row className="my-4">
        <Col   xl={4} lg={4} md={4} sm={12} xs={12}>
        <div className="MegaMenuVer">
              <MegaMenu />
            </div>
        </Col>
        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
        <BolimDetalis bolimData={bolimData}/>
     
        </Col>
      </Row>
      </Container>
      <FooterDesktop />
    </Fragment>
  );
}
