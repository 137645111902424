import React, { Fragment, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import AppURL from "../../api/AppURL";
import news1 from "../../assets/images/1.jpg";
import news2 from "../../assets/images/2.jpg";
import news3 from "../../assets/images/3.jpg";
import news4 from "../../assets/images/4.jpg";

export default function SliderMenu() {
  var settings = {
    dots: true, // pastdagi nuqtachalar
    infinite: true, // oxiriga borib tuxtamaslik
    speed: 500, //tezligi
    autoplay: true, //avtomatik aylanib turishi
    autoplaySpeed: 3000, //avtomatik aylanib tezligi
    slidesToShow: 1, // sliderlarni nechtaligi
    slidesToScroll: 1, // sliderlarni nechtadan o'tishi
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          
        },
        
      },
      
      {
        
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [slider, setSlider] = useState([]);

  useEffect(() => {
    axios
      .get(AppURL.AllSlider)
      .then((res) => {
        setSlider(res.data);
      })
      .catch((error) => {});
  }, [setSlider]);

  const MyView = slider.slice(0, 3).map((sliderList, i) => {
    return (
      <div key={i.toString()}>
        <Card className="SliderCard">
          <img className="SliderImg" src={sliderList.slider_img} />
        </Card>
      </div>
    );
  });

  return (
    <div>
      <Slider {...settings}>{MyView}</Slider>
    </div>
  );
}
