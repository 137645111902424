import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";

import logo from "../../assets/images/logo.png";

export default function NavMenu() {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState();
  const btnRef = useRef();

  const AccardionClick = (index) => {
    setIndex(index);
    setOpen(!open);
  };

  useEffect(() => {
    const closeDrop = (event) => {
      if (!btnRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDrop);
    // return () => document.removeEventListener("mousedown", closeDrop);
  }, []);

  return (
   
      <Row className="mt-2 NavMenu">
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <Link to="#" className="NavbarTopLink ms-5">
            <img className="LogoImg" src={logo} />
            <span>Navoiy davlat konchilik va texnologiyalar universiteti</span>
          </Link>
        </Col>
        <Col xl={9} lg={9} md={9} sm={12} xs={12}>
          <ul className="NavUl" ref={btnRef}>
            <li
              className={open && index === 1 ? "accardion open" : "accardion"}
              onClick={() => AccardionClick(1)}
            >
              <Link to="#" className="NavMenuLink">
                <span>Yangiliklar </span>
                <i class="fas fa-chevron-down"></i>
              </Link>
              <div className="accardionContent">
                <ul>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Kafedra</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Fakultetlar</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Bo'limlar</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Markaz</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li
              // ref={btnRef}
              className={open && index === 2 ? "accardion open" : "accardion"}
              onClick={() => AccardionClick(2)}
            >
              <Link to="#" className="NavMenuLink">
                <span>Abiturentlar</span>
                <i class="fas fa-chevron-down"></i>
              </Link>
              <div className="accardionContent">
                <ul>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>qabul</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Fakultetlar</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Bo'limlar</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="accardionContentLink" to="#">
                      <span>Markaz</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="">
              <Link to="#" className="NavMenuLink">
                <span>Talabalar</span>
                <i class="fas fa-chevron-down"></i>
              </Link>
            </li>
            <li className="">
              <Link to="#" className="NavMenuLink">
                <span>Doktarantlar</span>
                <i class="fas fa-chevron-down"></i>
              </Link>
            </li>
            <li className="">
              <Link to="#" className="NavMenuLink">
                <span>Bitiruvchilar</span>
                <i class="fas fa-chevron-down"></i>
              </Link>
            </li>
            <li className="">
              <Link to="#" className="NavMenuLink">
                <span>Hamkorlar</span>
                <i class="fas fa-chevron-down"></i>
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    
  );
}
