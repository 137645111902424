import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import HomeTop from "../components/home/HomeTop";
import News from "../components/home/News";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import FooterDesktop from "../components/common/FooterDesktop";
import Recomendation from "../components/home/Recomendation";
import axios from "axios";
import AppURL from "../api/AppURL";

export default function HomePage() {
  const [kafedraDataR, setkafedraDataR] = useState([]);
  const [yangiliklarData, setyangiliklarData] = useState([]);

  useEffect(() => {
    GetVisitorDetails();
    axios
      .get(AppURL.AllKafedralar)
      .then((res) => {
        let StatusCode = res.status;
        if (StatusCode === 200) {
          setkafedraDataR(res.data);
        }
      })
      .catch((error) => {});
    axios
      .get(AppURL.AllYangiliklar)
      .then((res) => {
        let StatusCode = res.status;
        if (StatusCode === 200) {
          setyangiliklarData(res.data);
        }
      })
      .catch((error) => {});
  }, []);

  const GetVisitorDetails = () => {
    axios.get(AppURL.VisitorDetails).then().catch();
  };
  return (
    <Fragment>
      <div className="Desktop">
        <NavMenuDesktop />
      </div>
      {/* <div className="Mobile">
        <NavMenuMobile/>
        </div> */}
      <HomeTop />
      <News yangiliklarData={yangiliklarData} />
      <Recomendation kafedraDataR={kafedraDataR} />
      <FooterDesktop />
    </Fragment>
  );
}
