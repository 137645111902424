import React, { Fragment, useEffect,  useState } from "react";
import { useParams } from "react-router-dom";
import HomeTop from "../components/home/HomeTop";
import News from "../components/home/News";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import FooterDesktop from "../components/common/FooterDesktop";

import Recomendation from "../components/home/Recomendation";
import SubCategory from "../components/OtherPage/SubCategory";
import axios from "axios";
import AppURL from "../api/AppURL";



export default function InfoSubcategoryPage(props) {

  const {category} = useParams();
  const {subcategory} = useParams();

  const [subcategoryData, setSubCategoryData] = useState([]);

  
  useEffect(()=>{   
    axios
      .get(AppURL.InfoListBySubCategory(category, subcategory))
      .then((res) => {
        let StatusCode = res.status;
        if(StatusCode===200){
          setSubCategoryData(res.data);
        }
      })
      .catch((error) => {});
  }, [category, subcategory])
  return (
    <Fragment>
      <div className="Desktop">
        <NavMenuDesktop />
      </div>
      {/* <div className="Mobile">
        <NavMenuMobile/>
        </div> */}

      
        <SubCategory category={category} subcategory={subcategory} subcategoryData={subcategoryData}/>
    
      <FooterDesktop />
    </Fragment>
  );
}
