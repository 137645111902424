import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mudir from "../../assets/images/kafedra/asqarov.jpg";
import { IoBookmarkSharp, IoSchoolSharp } from "react-icons/io5";
import ReactHTMLParser from "react-html-parser";

import { MdEmail } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useTranslation, initReactI18next } from "react-i18next";

export default function NewsBlock(props) {

  const MyList = props.newsblockdata;
  const { t } = useTranslation();

  const news_text=t('news_text');
  const news=t('news');


  const MyView = MyList.map((NewsList, i) => {
    return (
      <Col key={i.toString()} xl={4} lg={4} md={12} sm={12} xs={12} className="mt-2">
         <Card className="Card">
          <Card.Img className="CardImg" variant="top" src={NewsList.img} />
          <Card.Body>
            <Card.Text>
              <p className="CardText">  
                {/* {NewsList[news_text]}... */}
                {ReactHTMLParser(NewsList[news].substring(0, 90))}..
                <hr />
              </p>
            </Card.Text>
            <div className="DataLink">
              <p className="CardData">
                {" "}
                <FaRegCalendarAlt className="me-2" /> {NewsList.update_at}
              </p>
              <Link to={"/news/" + NewsList.id} className="LinkNews">
                Batafsil
              </Link>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  });

  return (
    <Fragment>
     
      <Row className="KafedraRow">
      <h1 className="text-center mb-3">Yangiliklar</h1>
        {MyView} 
      </Row>
     
    </Fragment>
  );
}
