import React from "react";
import { Routes, Route, Router } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import InfoMainPage from "../pages/InfoMainPage";
import InfoSubcategoryPage from "../pages/InfoSubcategoryPage";
import VerInfoSubcategoryPage from "../pages/VerInfoSubcategoryPage";
// import InfoCategoryPage from "../pages/InfoCategoryPage";
import ChairPage from "../pages/ChairPage";

import KafedraPage from "../pages/KafedraPage";
import RahbariyatPage from "../pages/RahbariyatPage";
import RahbariyatDetalPage from "../pages/RahbariyatDetalPage";
import NewsDetailsPage from "../pages/NewsDetailsPage";
import FakultetPage from "../pages/FakultetPage";
import FakultetDetalisPage from "../pages/FakultetDetalisPage";
import BolimPage from "../pages/BolimPage";
import NewsBlockPage from "../pages/NesBlockPage";
import BolimDetalisPage from "../pages/BolimDetalisPage";
import { Switch } from "react-router";

export default function AppRoute() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="infomain" element={<InfoMainPage />} />
      <Route
        path="infosubcategory/:category/:subcategory"
        element={<InfoSubcategoryPage />}
      />
      <Route
        path="verinfosubcategory/:vercategory/:versubcategory"
        element={<VerInfoSubcategoryPage />}
      />
      <Route path="chair/:id" element={<ChairPage />} />
      <Route path="kafedra" element={<KafedraPage />} />
      <Route path="rahbariyat" element={<RahbariyatPage />} />
      <Route path="rahbariyat/:id" element={<RahbariyatDetalPage />} />
      <Route path="news" element={<NewsBlockPage />} />
      <Route path="news/:id" element={<NewsDetailsPage />} />
      <Route path="fakultet" element={<FakultetPage />} />
      <Route path="fakultet/:id" element={<FakultetDetalisPage />} />
      <Route path="bolim" element={<BolimPage />} />
      <Route path="bolim/:id" element={<BolimDetalisPage />} />
     
    </Routes>
  );
}
