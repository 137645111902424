import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import AppURL from "../../api/AppURL";
import { useTranslation, initReactI18next } from "react-i18next";

export default function MegaMenu() {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState();
  const [vercategory, setverCategory] = useState([]);
  const btnRef = useRef();
  const { t } = useTranslation();

  const categoryt=t('mcategory');
  const subcategoryt=t('msubcategory');
  const AccardionClick = (index) => {
    setIndex(index);
    setOpen(!open);
  };

  useEffect(() => {
    axios
      .get(AppURL.AllVerCategory)
      .then((res) => {
        setverCategory(res.data);
      })
      .catch((error) => {});
    const closeDrop = (event) => {
      if (!btnRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", closeDrop);
  }, [setverCategory]);

  

  const MyView = vercategory.map((vercategoryList, i) => {
    return (
      <li
        key={i.toString()}
        className={
          open && index === vercategoryList.id ? "accardion open" : "accardion"
        }
        onClick={() => AccardionClick(vercategoryList.id)}
      >
        <Link to="#" className="acardionMenuLink">
          <span>
            <img src={vercategoryList.photo} />
            <p>{vercategoryList[categoryt]}</p>
          </span>
          <i className="fas fa-chevron-down"></i>
        </Link>

        <div className="accardionContentver">
          <ul>
            {vercategoryList.versubcategory_uz.map((versubcategoryList, s) => {
              return (
                <li key={s.toString()}>
                  <Link
                    className="accardionContentLink"
                    to={
                      versubcategoryList.sub_vercategory_uz === "Kafedralar"
                        ? "/kafedra"
                        : versubcategoryList.sub_vercategory_uz === "Rahbariyat"
                        ? "/rahbariyat"
                        : versubcategoryList.sub_vercategory_uz === "Fakultetlar"
                        ? "/fakultet"
                        : versubcategoryList.sub_vercategory_uz === "Markazlar va bo'limlar"
                        ? "/bolim"
                        : "/verinfosubcategory/" +
                          vercategoryList.ver_category_uz +
                          "/" +
                          versubcategoryList.sub_vercategory_uz
                    }
                  >
                    {/* <Link className="accardionContentLink" to={SelectCategory()}> */}
                    <span>{versubcategoryList[subcategoryt]}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
    );
  });

  return (
    <div className="accardionMenu">
      <ul className="accardinTitle" ref={btnRef}>
        {MyView}
      </ul>
    </div>
  );
}
