import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mudir from "../../assets/images/kafedra/asqarov.jpg";
import { BsFillPersonFill, IoBookmarkSharp } from "react-icons/io5";
import ReactHTMLParser from "react-html-parser";
import { useTranslation } from "react-i18next";

import { MdEmail } from "react-icons/md";

export default function Fakultet(props) {
  const { t } = useTranslation();

  const faculty_name = t("faculty_name");
  const faculty_main = t("faculty_main");
  const MyList = props.fakultetData;

  const MyView = MyList.map((FakultetList, i) => {
    return (
      <Col key={i.toString()} xl={6} lg={6} md={12} sm={12} xs={12}>
        <Link to={"/fakultet/" + FakultetList.id} className="KafedraLink">
          <div className="KafedraMudir">
            <span>
              <IoBookmarkSharp className="Iobook" />

              {FakultetList[faculty_name]}
            </span>
          </div>
        </Link>
      </Col>
    );
  });

  return (
    <Fragment>
      <Row className="KafedraRow">
        <h1 className="text-center mb-3">{faculty_main}</h1>
        {MyView}
      </Row>
    </Fragment>
  );
}
