import React, { Fragment, useEffect,  useState } from "react";
import { useParams } from "react-router-dom";
import HomeTop from "../components/home/HomeTop";
import News from "../components/home/News";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import FooterDesktop from "../components/common/FooterDesktop";

import Recomendation from "../components/home/Recomendation";
import VerSubcategory from "../components/OtherPage/VerSubcategory";
import axios from "axios";
import AppURL from "../api/AppURL";



export default function VerInfoSubcategoryPage(props) {
  const {vercategory} = useParams();
  const {versubcategory} = useParams();

  const [versubcategoryData, setverSubCategoryData] = useState([]);


  useEffect(()=>{
    axios
      .get(AppURL.VerInfoListBySubCategory(vercategory, versubcategory))
      .then((res) => {
        let StatusCode = res.status;
        if(StatusCode===200){ 
          setverSubCategoryData(res.data);
        }
      })
      .catch((error) => {});
  },[vercategory, versubcategory])
  return (
    <Fragment>
      <div className="Desktop">
        <NavMenuDesktop />
      </div>
      {/* <div className="Mobile">
        <NavMenuMobile/>
        </div> */}

      {/* <HomeTop /> */}
        <VerSubcategory vercategory={vercategory} versubcategory={versubcategory} versubcategoryData={versubcategoryData}/>
      {/* <News />
      <Recomendation /> */}
      <FooterDesktop />
    </Fragment>
  );
}
