import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import { ReactDOM } from "react-dom";
import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import axios from "axios";
import AppURL from "../../api/AppURL";
import NavLogo from "./NavLogo";
import { useTranslation, initReactI18next } from "react-i18next";

import logo from "../../assets/images/logo.png";


export default function NavMenu(props) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState();
  const [category, setCategory] = useState([]);
  const btnRef = useRef();

  const { t } = useTranslation();

  const categoryt=t('category');
  const subcategoryt=t('subcategory');

  const AccardionClick = (index) => {
    setIndex(index);
    setOpen(!open);
  };

  const openLanguage = props.openLanguage;
  // console.log(t('category'));

  useEffect(() => {
    axios
      .get(AppURL.AllCategory)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((error) => {});

    const closeDrop = (event) => {
      if (!btnRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", closeDrop);
  }, [setCategory]);

  const MyView = category.map((categoryList, i) => {
    return (
      <li
        key={i.toString()}
        className={
          open && index === categoryList.id ? "accardion open" : "accardion"
        }
        onClick={() => AccardionClick(categoryList.id)}
      >
        <Link to="#" className="NavMenuLink">
          <span>
            {/* {categoryList.category_uz} */}
           {categoryList[categoryt]} 
            {/* {openLanguage === "ru" ? categoryList.category_ru : openLanguage === "en"  ? categoryList.category_en : categoryList.category_uz} */}
          </span>
        
          <i className="fas fa-chevron-down"></i>
        </Link>
        <div className="accardionContent">
          <ul>
            {categoryList.subcategory_uz.map((subcategoryList, s) => {
              return (
                <li key={s.toString()}>
                  <Link
                    className="accardionContentLink"
                    to={
                      subcategoryList.sub_category_uz === "Yangiliklar"
                        ? "/news"
                        : "/infosubcategory/" +
                          categoryList.category_uz +
                          "/" +
                          subcategoryList.sub_category_uz
                    }
                  >
                    <span>
                      {/* {openLanguage === "ru" ? subcategoryList.sub_category_ru : openLanguage === "en"  ? subcategoryList.sub_category_en : subcategoryList.sub_category_uz} */}
                    {subcategoryList[subcategoryt]}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
      
    );
  });

  return (
    <Row className="mt-2 NavMenu">
      <Col xl={2} lg={2} md={2} sm={12} xs={12}>
        <Link to="/" className="NavbarTopLink ms-5">
          <img className="LogoImg" src={logo} />
          <span>NDKTU</span>
        </Link>
        {/* <h2>{t('category')}</h2> */}
      </Col>
      <Col xl={10} lg={10} md={10} sm={12} xs={12}>
        <ul className="NavUl" ref={btnRef}>
          {MyView}
        </ul>
      </Col>
    </Row>
  );
}
