import { Component } from "react";

class AppURL extends Component {
  // static BaseURL = "http://127.0.0.1:8000/api";
  static BaseURL = "https://admin.nsumt.uz/api";
  // static BaseURL = "https://admin.nsmtu.uz/api";
  static VisitorDetails = this.BaseURL + "/getvisitor";

  static AllSlider = this.BaseURL + "/allslider";
  static AllCategory = this.BaseURL + "/allcategory";
  static AllVerCategory = this.BaseURL + "/allvercategory";
  static AllInfoMain = this.BaseURL + "/allinfo";
  static AllVerInfo = this.BaseURL + "/allverinfo";
  static AllKafedralar = this.BaseURL + "/allkafedralar";
  static AllRahbariyat = this.BaseURL + "/rahbariyat";
  static AllYangiliklar = this.BaseURL + "/yangiliklar";
  static AllFakultet = this.BaseURL + "/fakultetlar";
  static AllBolim = this.BaseURL + "/bolim";
  static AllNewsBlock = this.BaseURL + "/yangiliklar";

  static KafedralarById(id) {
    return this.BaseURL + "/allkafedralarbyid/" + id;
  }

  static FakultetlarById(id) {
    return this.BaseURL + "/fakultetlar/" + id;
  }
  static BolimlarById(id) {
    return this.BaseURL + "/bolim/" + id;
  }


  static RahbariyatDetalById(id) {
    return this.BaseURL + "/rahbariyat/" + id;
  }



  static YangiliklarById(id) {
    return this.BaseURL + "/yangiliklar/" + id;
  }

  static InfoListBySubCategory(category, subcategory) {
    return (
      this.BaseURL + "/infolistbysubcategory/" + category + "/" + subcategory
    );
  }
  static VerInfoListBySubCategory(vercategory, versubcategory) {
    return (
      this.BaseURL +
      "/verinfolistbysubcategory/" +
      vercategory +
      "/" +
      versubcategory
    );
  }
}

export default AppURL;
