import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import mudir from "../../assets/images/kafedra/asqarov.jpg";
import { BsFillPersonFill, IoBookmarkSharp } from "react-icons/io5";
import ReactHTMLParser from "react-html-parser";

import { MdEmail } from "react-icons/md";

export default function Bolim(props) {
  const MyList = props.bolimData;

  const MyView = MyList.map((BolimList, i) => {
    return (
      <Col key={i.toString()} xl={6} lg={6} md={12} sm={12} xs={12}>
        <Link to={"/bolim/"+BolimList.id} className="KafedraLink">
          <div className="KafedraMudir">
            <span>
            <IoBookmarkSharp className="Iobook" />
             
              {BolimList.name_uz}
            </span>
          </div>
        </Link>
      </Col>
    );
  });

  return (
    <Fragment>
     
      <Row className="KafedraRow">
      <h1 className="text-center mb-3">Bo'limlar</h1>
        {MyView}
       
      </Row>
     
    </Fragment>
  );
}
