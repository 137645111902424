import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { Card, Col, Container, Row } from "react-bootstrap";
import news from "../../assets/images/1.jpg";
import news1 from "../../assets/images/1.jpg";
import news2 from "../../assets/images/2.jpg";
import news3 from "../../assets/images/3.jpg";
import news4 from "../../assets/images/4.jpg";
import ReactHTMLParser from "react-html-parser";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useTranslation, initReactI18next } from "react-i18next";



export default function SubCategory(props) {
  const MyList = props.subcategoryData;
  const [newsData, setnewsData] = useState([]);
  const Category = props.category;
  const SubCategory = props.subcategory;
  const { t } = useTranslation();

  const gminfo=t('gminfo');
  const gminfo_title=t('gminfo_title');
  
  useEffect(()=>{   
    axios
      .get(AppURL.AllYangiliklar)
      .then((res) => {
        let StatusCode = res.status;
        if(StatusCode===200){
          setnewsData(res.data);
        }
      })
      .catch((error) => {});
  }, []);
  const MyViewNews = newsData.slice(0, 4).map((NewsList, i) => {
    return (
      <div key={i.toString()}>
         
                <Card className="mb-2">
                  <Card.Img className="CardImg" variant="top" src={NewsList.img}/>
                  <Card.Body className="m-1 p-1">
                    <Card.Text className="">
                      <p className="CardText ">
                      {NewsList.title_uz.substring(0, 90)}...
                      </p>
                    </Card.Text>
                    <div className="DataLinkBlock">
                      <p>
                        <i className="fa-regular fa-calendar-days"></i>
                      </p>
                      <p className="CardData m-0 p-0">
                      <FaRegCalendarAlt className="me-2" /> {NewsList.update_at}
                      </p>
                      <Link to={"/news/" + NewsList.id} className="LinkNews">
                Batafsil
              </Link>
                    </div>
                  </Card.Body>
                </Card>
            
      </div>
    );
  });
  const MyView = MyList.map((SubCategoryList, i) => {
    return (
      <div key={i.toString()}>
        <div className="InfoBradCamp">
          <span className="CategoryBrad">{Category}</span>
          <i className="fa fa-chevron-right"></i>
          <i className="fa fa-chevron-right"></i>
          <span className="SubCategoryBrad">{SubCategory}</span>
        </div>
        <div className="InfoMain">
          <h3>{SubCategoryList[gminfo]} </h3>
          <hr />
          <div className="OtherTextNews">
            {ReactHTMLParser(SubCategoryList[gminfo_title])}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Fragment>
      <Container>
        <Row className="my-2">
          <Col xl={9} lg={9} md={9} sm={12} xs={12}>
            {MyView}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="">
            <Row className="MegaMenuVer">
            <Col className="mb-2" xl={12} lg={12} md={12} sm={12} xs={12}>
              {MyViewNews}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
