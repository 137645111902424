import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { Card, Col, Container, Row } from "react-bootstrap";
import news from "../../assets/images/1.jpg";
import news1 from "../../assets/images/1.jpg";
import news2 from "../../assets/images/2.jpg";
import news3 from "../../assets/images/3.jpg";
import news4 from "../../assets/images/4.jpg";
import { useTranslation, initReactI18next } from "react-i18next";

export default function InfoMain() {
  const { t } = useTranslation();

  const categoryt=t('mcategory');
  const subcategoryt=t('msubcategory');
  return (
    <Fragment>
      <Container>
        <Row className="my-2">
          <Col xl={9} lg={9} md={9} sm={12} xs={12}>
            <h2>Sarlavha </h2>
            <p className="OtherTextNews">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci
              consectetur delectus doloribus Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Adipisci consectetur delectus
              doloribus Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Adipisci consectetur delectus doloribus Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Adipisci consectetur
              delectus doloribus
            </p>
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="">
            <Row className="">
              <Col className="mb-2" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card className="Card">
                  <Card.Img className="CardImg" variant="top" src={news1} />
                  <Card.Body className="m-0 p-0">
                    <Card.Text className="m-0 p-0">
                      <p className="CardText" p-0 m-0>
                        {" "}
                        Ichki ishlar xodimlari bilan birgalikda tadbir tashkil
                        etildi{" "}
                      </p>
                    </Card.Text>
                    <div className="DataLink m-0 p-0">
                      <p>
                        <i className="fa-regular fa-calendar-days"></i>
                      </p>
                      <p className="CardData">25.06.2022</p>
                      <Link to="/news" className="Link">
                        Batafsil
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-2" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card className="Card">
                  <Card.Img className="CardImg" variant="top" src={news2} />
                  <Card.Body className="m-0 p-0">
                    <Card.Text className="m-0 p-0">
                      <p className="CardText" p-0 m-0>
                        {" "}
                        Ichki ishlar xodimlari bilan birgalikda tadbir tashkil
                        etildi{" "}
                      </p>
                    </Card.Text>
                    <div className="DataLink m-0 p-0">
                      <p>
                        <i className="fa-regular fa-calendar-days"></i>
                      </p>
                      <p className="CardData">25.06.2022</p>
                      <Link to="/news" className="Link">
                        Batafsil
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="mb-2" xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card className="Card">
                  <Card.Img className="CardImg" variant="top" src={news3} />
                  <Card.Body className="m-0 p-0">
                    <Card.Text className="m-0 p-0">
                      <p className="CardText" p-0 m-0>
                        {" "}
                        Ichki ishlar xodimlari bilan birgalikda tadbir tashkil
                        etildi{" "}
                      </p>
                    </Card.Text>
                    <div className="DataLink m-0 p-0">
                      <p>
                        <i className="fa-regular fa-calendar-days"></i>
                      </p>
                      <p className="CardData">25.06.2022</p>
                      <Link to="/news" className="Link">
                        Batafsil
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
