import React, { Component, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavMenuDesktop from "../components/common/NavMenuDesktop";
  
import InfoMain from "../components/OtherPage/InfoMain";
import FooterDesktop from "../components/common/FooterDesktop";

export default function InfoMainPage() {

  useEffect(()=>{
    window.scroll(0,0);
  })


  return (
    <Fragment>
      <div className="Desktop">
        <NavMenuDesktop />
      </div>
      <InfoMain />
      <FooterDesktop />
    </Fragment>
  );
}
