import React, { Fragment, useEffect, useRef, useState } from "react";

import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import book from "../../assets/images/icons/saving_book.png";
import hemis from "../../assets/images/icons/hemis.png";
import symbol from "../../assets/images/symbols/symbol.svg";
import english from "../../assets/images/languages/english.svg";
import russian from "../../assets/images/languages/russian.svg";
import uzbek from "../../assets/images/languages/uzbek.svg";

export default function NavLogo() {
  

  return (
    <Row className="NavbarTopRow">
      <Col xl={12} lg={12} md={12} sm={12} xs={12} className="EduCol">
        <Button className="ms-2">
          <i className="fa fa-bars"></i>
        </Button>
        <a
          href="https://hemis.ndki.uz/dashboard/login"
          className="EduLink text-right mx-2"
          target="_blank"
        >
          <img className="hemis" src={hemis} />
          <span>Hemis</span>
        </a>
        <a href="https://ve.ndki.uz/" className="EduLink" target="_blank">
          <img className="hemis" src={book} />
          <span>Moodle</span>
        </a>
      </Col>
     

      
    </Row>
  );
}
